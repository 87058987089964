import { FC, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import type { IShopDataResponse } from 'typings/shopApi'
import type { IApiError, IGenericServerError } from 'typings/errorTypes'

import config from 'config'
import { measurementUnitMap } from 'utils/unitUtils'
import { fetchShopData } from 'services/shop'

import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'
import { useShopContext } from 'context/shop'
import { useCartContext } from 'context/cart'

import {
  containerVariants,
  itemVariants,
  listVariants,
  titleVariants
} from './ProductListPage.motion'

import PersistentPortal from 'components/common/PersistentPortal'
import ProductCard from 'components/product/ProductCard'
import ShopBreadcrumbs from 'components/shop/ShopBreadcrumbs'
import MessageBox from 'components/common/MessageBox'
import PaginationControls from 'components/common/PaginationControls'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import ProductSearch from 'components/shop/ProductSearch'
import MessageList from 'components/common/MessageList'
import PromoBanner from 'components/common/PromoBanner'

const defaultPageNumber = config.defaultPageNumber
const defaultProductPageSize = config.defaultProductPageSize

const ProductListPage: FC = () => {
  const location = useLocation()
  const { localeCode: localeCodePathParam, shopId: shopIdPathParam } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { accessToken } = useAuthContext()
  const { t: translated } = useTranslation()
  const { cartId, configuredLanguages, setIsPromoCodeEnabled, setConfiguredLanguages } =
    useAppContext()
  const { addProductsToMap } = useShopContext()
  const { addUnitsToCart, cartItems, isCartContentLoading, isCartUploading } = useCartContext()

  const pageNumber = searchParams.get('pageNumber') ?? defaultPageNumber
  const pageSize = searchParams.get('pageSize') ?? defaultProductPageSize
  const searchQuery = searchParams.get('search') ?? ''

  const setPageNumber = useCallback(
    (pageNum: number) => {
      searchParams.set('pageNumber', String(pageNum))
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const {
    data: shopData,
    error: shopDataQueryError,
    isSuccess: isShopDataQuerySuccess,
    isLoading: isShopDataLoading,
    isError: isShopDataQueryError
  } = useQuery<IShopDataResponse, IApiError | IGenericServerError>({
    enabled: !!shopIdPathParam && !!localeCodePathParam,
    queryKey: ['products', shopIdPathParam, localeCodePathParam, pageNumber, pageSize, searchQuery],
    queryFn: () =>
      fetchShopData({
        shopId: shopIdPathParam ?? '',
        accessToken: accessToken,
        localeCode: localeCodePathParam ?? '',
        pageNumber,
        pageSize,
        search: searchQuery
      }) as Promise<IShopDataResponse>,
    staleTime: Infinity
  })

  useEffect(() => {
    if (!isShopDataQuerySuccess || shopData == null) return

    if (shopData?.isPromoCodeEnabled != null) setIsPromoCodeEnabled(shopData?.isPromoCodeEnabled)

    if (
      shopData.languages?.length > 0 &&
      configuredLanguages?.length <= 1 &&
      configuredLanguages?.length != shopData.languages?.length
    )
      setConfiguredLanguages(shopData.languages)

    addProductsToMap(shopData?.productCatalog?.results)
  }, [
    addProductsToMap,
    configuredLanguages?.length,
    isShopDataQuerySuccess,
    setConfiguredLanguages,
    setIsPromoCodeEnabled,
    shopData
  ])

  const handleAddToCart = useCallback(
    (articleNumber: string) => () => {
      if (!articleNumber || !cartId || isCartUploading) return
      addUnitsToCart(articleNumber, 1)
    },
    [addUnitsToCart, cartId, isCartUploading]
  )

  const numTotalItems = shopData?.productCatalog?.paginationInfo?.totalRecords ?? 0
  const productList = shopData?.productCatalog?.results ?? []
  const filteredProductList = productList.filter(
    product =>
      product?.restrictions?.maxQuantityPerCustomer !== 0 &&
      product?.restrictions?.maxQuantityPerOrder !== 0
  )
  const promoBanners = shopData?.promotions?.banners ?? []

  return (
    <>
      <ShopBreadcrumbs
        routeSymbols={[
          <p key='breadcrumb-product-list' className='inline-block capitalize'>
            {translated('Product list')}
          </p>
        ]}
        customClasses={'max-w-4xl mx-auto'}
      />

      <ProductSearch setPageNumber={setPageNumber} />

      <motion.div
        className='relative container max-w-4xl mx-auto pt-0 pb-8 px-0'
        variants={containerVariants}
      >
        {/* ---------- select page size option: temporarily hidden ---------- */}
        {/* <div className='relative z-10 w-full mb-2 md:mb-4 pb-2 px-4 rounded-md'>
          <div className='ml-auto flex items-center justify-end gap-2'>
            <p className='text-sm'>{translated('Number of items per page')}</p>
            <Select
              options={pageSizeOptions}
              selected={pageSize}
              setSelected={selectedPageSize => {
                setPageNumber(1)
                setPageSize(selectedPageSize)
              }}
              className='w-16'
            />
          </div>
        </div> */}

        <div className='w-full mb-4'>
          <PromoBanner data={promoBanners} />
        </div>

        {isShopDataQueryError ? (
          <div className='min-h-full grid place-content-center'>
            <MessageBox
              type='error'
              text={
                'status' in shopDataQueryError && 'title' in shopDataQueryError
                  ? `${shopDataQueryError?.status} ${shopDataQueryError?.title}`
                  : `${shopDataQueryError?.statusCode} ${shopDataQueryError?.message}`
              }
              showIcon={true}
            />
          </div>
        ) : isShopDataLoading ? (
          <div className='min-h-full grid place-content-center'>
            <SpinnerPM>
              <CloudDownloadIcon />
            </SpinnerPM>
          </div>
        ) : filteredProductList.length > 0 ? (
          <motion.ul
            className='relative z-0 flex justify-center flex-wrap gap-2'
            variants={listVariants}
          >
            {filteredProductList?.map(product => {
              const {
                articleNumber,
                details: { attributes, basePrice, displayPrice, currencyCode, media, points },
                inStock,
                restrictions
              } = product
              const name = attributes?.name?.value
              const description = attributes?.description?.value
              const imageUrl = media.find(mediaObj => mediaObj?.type === 'image')?.url
              const restrictionValueList = Object.values(restrictions ?? {})
              const maxAmount =
                restrictionValueList?.length > 0 ? Math.min(...restrictionValueList) : Infinity
              const netWeight = product?.details?.netWeight
              const netWeightUnit = product?.details?.netWeightUnit
              const measurementUnitDisplayed = measurementUnitMap.get(netWeightUnit ?? '')
              const isPromotional = (product?.tags ?? []).includes('promotion')

              return (
                <AnimatePresence key={articleNumber}>
                  <motion.li variants={itemVariants}>
                    <ProductCard
                      name={name}
                      description={description}
                      imageUrl={imageUrl}
                      points={points}
                      currencyCode={currencyCode}
                      basePrice={basePrice}
                      finalPrice={displayPrice}
                      inStock={inStock}
                      isPromotional={isPromotional}
                      maxAmount={maxAmount}
                      amountInCart={
                        cartItems.find(item => item.articleNumber === articleNumber)?.quantity
                      }
                      amountInUnits={netWeight}
                      unit={measurementUnitDisplayed}
                      productInfoLink={{ pathname: `${articleNumber}`, search: location.search }}
                      onPurchaseAction={handleAddToCart(articleNumber)}
                      isCartUploading={isCartUploading}
                      isCartContentLoading={isCartContentLoading}
                    />
                  </motion.li>
                </AnimatePresence>
              )
            })}
          </motion.ul>
        ) : (
          <div className='min-h-full grid place-content-center'>
            <div className='h-24 w-full p-4 grid place-content-center rounded-md border border-secondary-darker'>
              <p className='text-gray-500'>
                {searchQuery.length > 0
                  ? translated('No results match your search')
                  : translated('There are no products available in this shop')}
              </p>
            </div>
          </div>
        )}

        <PaginationControls
          currentPage={Number(pageNumber)}
          itemsPerPage={Number(pageSize)}
          totalItems={numTotalItems}
          setCurrentPage={setPageNumber}
          className='mt-2 p-2'
        />
      </motion.div>

      <PersistentPortal containerElementSelector='#sticky-footer'>
        <motion.div variants={titleVariants}>
          {/* Message boxes do not stand out on the PM-blue theme */}
          <MessageList
            messages={(shopData?.disclaimers ?? [])?.map((disclaimer, index) => ({
              id: disclaimer?.localizedLabels?.[0]?.name ?? index,
              text: disclaimer?.localizedLabels?.[0]?.info ?? '',
              visible: true
            }))}
          />
        </motion.div>
      </PersistentPortal>
    </>
  )
}

export default ProductListPage
