import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft } from 'react-icons/fa6'
import { ImSpinner2 } from 'react-icons/im'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper'
import 'swiper/css'

import config from 'config/index'
import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'
import { useLocalizationContext } from 'context/localization'
import { fetchPromoCodeInfo } from 'services/checkout'
import { showToast } from 'components/layout/ToastNotification'
import Modal from 'components/common/Modal'
import Button from 'components/common/buttons/Button'
import TextInput from 'components/common/TextInput'

type LMProps = {
  isOpen: boolean
  onClose?: () => void
}
const LoginModal: FC<LMProps> = ({ isOpen, onClose }) => {
  const { t: translated } = useTranslation()
  const { signIn } = useAuthContext()
  const { appLocale } = useLocalizationContext()
  const { isPromoCodeEnabled, userPromoCode, shopId } = useAppContext()

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const [promoCodeInput, setPromoCodeInput] = useState(userPromoCode ?? '')
  const [isValidatingPromoCode, setIsValidatingPromoCode] = useState(false)
  const [promoCodeInputErrors, setPromoCodeInputErrors] = useState<string[]>([])

  const promoCodeErrorMessage = translated('Promo code {{promoCode}} could not be applied', {
    promoCode: promoCodeInput ?? userPromoCode
  })

  const handleSignUpWithPromocode = useCallback(() => {
    if (isPromoCodeEnabled) {
      swiper?.slideNext()
    }
  }, [isPromoCodeEnabled, swiper])

  const handleSignUpWithoutPromocode = useCallback(() => {
    window.location.assign(`${config.registrationAppUrl}?shopId=${shopId}`)
  }, [shopId])

  const handleSignUp = useCallback(() => {
    if (userPromoCode) {
      return handleSignUpWithPromocode()
    } else {
      return handleSignUpWithoutPromocode()
    }
  }, [handleSignUpWithPromocode, handleSignUpWithoutPromocode, userPromoCode])

  const handlePromoCodeInputChange = useCallback((promoCodeInput: string) => {
    setPromoCodeInput(promoCodeInput?.trim())
    setPromoCodeInputErrors([])
  }, [])

  const handlePromoCodeValidation = useCallback(async () => {
    if (!promoCodeInput) return

    setIsValidatingPromoCode(true)
    if (promoCodeInputErrors.length) setPromoCodeInputErrors([])

    try {
      const promoCodeResponse = await fetchPromoCodeInfo(promoCodeInput)
      const { userId, countryCode, userName } = promoCodeResponse ?? {}
      if (!userId || !countryCode) throw new Error(translated('Invalid promo code'))

      showToast({
        type: 'success',
        title: userName
          ? translated(
              'Success! Promo code {{promoCode}} from your sponsor {{sponsorName}} is now active.',
              {
                promoCode: promoCodeInput,
                sponsorName: userName
              }
            )
          : translated('Promo code {{promoCode}} applied', {
              promoCode: promoCodeInput
            })
      })

      if (timeoutRef.current) clearTimeout(timeoutRef.current)

      const redirectUrl = new URL(window.location.href)
      redirectUrl.searchParams.append('autosignin', 'true')

      timeoutRef.current = setTimeout(() => {
        setIsValidatingPromoCode(false)
        window.location.assign(
          config.registrationAppUrl +
            `?country=${countryCode?.slice(0, 2)}` +
            `&lang=${appLocale?.slice(0, 2)}` +
            `&sponsorId=${userId}` +
            `&redirectUrl=${encodeURIComponent(redirectUrl.toString())}`
        )
      }, 2000)
    } catch (error) {
      setPromoCodeInputErrors([promoCodeErrorMessage])
      showToast({
        type: 'error',
        title: promoCodeErrorMessage
      })
      setIsValidatingPromoCode(false)
    }
  }, [appLocale, promoCodeErrorMessage, promoCodeInput, promoCodeInputErrors.length, translated])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm'>
      <Swiper onSwiper={setSwiper} slidesPerView={1} allowTouchMove={false}>
        <SwiperSlide>
          <div className='min-h-[360px] p-3 flex flex-col justify-center'>
            <div className='flex flex-col items-center'>
              <p>{translated("I'm an existing customer")}</p>
              <Button containerClassName='mt-4 w-full' variant='primary' onClick={signIn}>
                {translated('Sign In')}
              </Button>
            </div>

            <p
              className={
                'relative w-full mt-6 py-4 px-4 flex items-center ' +
                " before:content-[''] before:flex-grow before:h-px before:bg-gray-200 before:mr-4" +
                " after:content-[''] after:flex-grow after:h-px after:bg-gray-200 after:ml-4"
              }
            >
              {translated('or')}
            </p>

            <div className='flex flex-col'>
              <p className='text-center'>{translated("I'm a new customer")}</p>
              <Button containerClassName='mt-4' variant='outlined' onClick={handleSignUp}>
                {translated('Sign Up')}
              </Button>
              {!userPromoCode && isPromoCodeEnabled && (
                <Button
                  containerClassName='mt-4 w-full'
                  variant='text'
                  onClick={handleSignUpWithPromocode}
                >
                  {translated('Sign Up with Promo Code')}
                </Button>
              )}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='min-h-[360px] p-3 space-y-4'>
            <button className='flex gap-1 items-center' onClick={() => swiper?.slidePrev()}>
              <FaArrowLeft />
              <span>{translated('Back')}</span>
            </button>

            <div className=''>
              <div className='flex flex-col gap-2'>
                <div>
                  <p className='text-base'>{translated('Do you have a customer promo code?')}</p>
                  <p className='text-base'>
                    {translated(
                      'Enter it to get a {{percentage}}% discount on eligible products!',
                      { percentage: '5' }
                    )}
                  </p>
                </div>

                <TextInput<string>
                  id='promo-code-input'
                  placeholder={translated('Enter a promo code')}
                  value={promoCodeInput}
                  errors={promoCodeInputErrors}
                  changeHandler={handlePromoCodeInputChange}
                  customWrapperClasses='w-full'
                  customInputClasses='pr-16'
                />

                <Button
                  onClick={handlePromoCodeValidation}
                  containerClassName='w-full'
                  variant='primary'
                  disabled={!!promoCodeInputErrors.length || !promoCodeInput}
                >
                  {isValidatingPromoCode ? (
                    <ImSpinner2 className='h-3 w-3 animate-spin' />
                  ) : (
                    translated('Apply and Sign Up')
                  )}
                </Button>
              </div>

              <p
                className={
                  'relative w-full mt-6 mb-0 px-4 flex items-center ' +
                  " before:content-[''] before:flex-grow before:h-px before:bg-gray-200 before:mr-4" +
                  " after:content-[''] after:flex-grow after:h-px after:bg-gray-200 after:ml-4"
                }
              >
                {translated('or')}
              </p>

              <Button
                containerClassName='mt-4 w-full'
                variant='text'
                onClick={handleSignUpWithoutPromocode}
              >
                {translated('Sign Up without Promo Code')}
              </Button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </Modal>
  )
}

export default LoginModal
